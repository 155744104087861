import React from 'react'
import checkMark from './images/icons/check-mark.png'
import cancel from './images/icons/cancel.png'

function ContactSection() {
  return (
    <section id='contactSection' className='contactSection'>
        <div className="full-bleed-wrapper">
            <h1 className="h1 contact-section-title">let's chat:</h1>
            <div className="contact-wrapper">
                <form action="https://formsubmit.co/kevin@mccanndesignsweb.com" method="POST" className="contact-form">
                    <input type="hidden" name="_subject" value="New submission!" />
                    <input type="hidden" name="_next" value="https://www.mccanndesignsweb.com/thankyou.html" />
                    <div className="form-group">
                        <input id='CustomerName' type="text" name='name' required placeholder=' ' />
                        <label htmlFor="CustomerName">Name</label>
                    </div>
                    <div className="form-group">
                        <input id='CustomerEmail' type="email" name='email' required placeholder=' ' />
                        <img loading="lazy"  src={checkMark} alt="" className="icon-CheckMark" />
                        <img loading="lazy"  src={cancel} alt="" className="icon-Cancel" />
                        <label htmlFor="CustomerEmail">Email</label>
                    </div>
                    <div className="form-group">
                        <textarea name="message" id="CustomerMessage" required placeholder=' '></textarea>
                        <label htmlFor="CustomerMessage">Message</label>
                    </div>
                    {/* <input type="hidden" name="_autoresponse" value="your custom message" /> */}
                    <button className='button button-submit' type="submit" value="submit">submit</button>
                </form>
            </div>
            <div className="contact-section-subheader">
                <h2 className="h2 contact-section-subtitle">Your Brand<br/> Deserves the Spotlight</h2>
            </div>
        </div>
    </section>
  )
}

export default ContactSection