import heroImage from './images/photos/Man_siting_and_thinking.png'
import fanBlade from './images/svgs/fanblade.svg'
import Button from './components/Button'

function HeroSection() {
    
  return (
    <section id='HeroSection' className='HeroSection'>
        
            <div className="hero-main full-bleed-wrapper">
                
                <h1 className='h1 hero-h1'>mccann designs</h1>
               
                    <div className="hero-image-wrapper">
                        <img  className='hero-image' src={heroImage} alt="" srcSet="" />
                        <div className="fan-group">
                            <img  className='fanBlade-1' src={fanBlade} alt="" srcSet="" />
                            <img  className='fanBlade-2' src={fanBlade} alt="" srcSet="" />
                            <img  className='fanBlade-3' src={fanBlade} alt="" srcSet="" />
                            <img  className='fanBlade-4' src={fanBlade} alt="" srcSet="" />
                            <img  className='fanBlade-5' src={fanBlade} alt="" srcSet="" />
                        </div>
                    </div>
                    <div className="hero-main-content">
                        <h2 className='h3'>Step Into the Spotlight with a Website That Steals the Show!</h2>
                        <div className="subheading-button-wrapper">
                            <Button openType={'_blank'} text='book a meeting' type='button-primary float-shadow' hrefLink={'https://calendly.com/kevin-mccanndesignsweb/30min'} />
                            <Button text ='my work' type='button-secondary float-shadow' hrefLink='#WorkSection' />
                        </div>
                    </div>
              
            </div>
        <div className="hero-background-asset"></div>

       
    </section>
  )
}

export default HeroSection