import Facebook from './images/photos/Facebook.png'
import Instagram from './images/photos/Instagram.png'
import LinkedIn from './images/photos/LinkedIn.png'
import TwitterX from './images/photos/TwitterX.png'
import YouTube from './images/photos/YouTube.png'

function Footer() {

  return (
    <section id='Footer-Section' className='footerSection full-bleed-wrapper'>
        <div className="footer-siteMap">
            <ul  className='siteMap-list'>
                <li><a className='button button-inline footerlink' href="/#AboutSection">about</a></li>
                <li><a className='button button-inline footerlink' href="/#WorkSection">work</a></li>
                <li><a className='button button-inline footerlink' href="/#ServiceSection">services</a></li>
                <li><a className='button button-inline footerlink' href="/#FQASection">FQA</a></li>
                <li><a className='button button-inline' href="/PrivacyPolicy">privacy policy</a></li>
                <li><a className='button button-inline' href="/Terms">terms & conditions</a></li>
            </ul>
        </div>
        <div className="footer-info">
            <div className="freelancer-info">
                <p className="body2">McCann Designs llc</p>
                <p className="body2">Carson City, Nv</p>
                <a href='mailto:kevin@mccanndesignsweb.com?subject=book%20a%20meeting' className="button button-inline">kevin@mccanndesignsweb.com</a>
            </div>
        </div>
        <div className="footer-social">
            <ul className="social-list">
                <li className='social-list-item'><a href="https://www.facebook.com/profile.php?id=61563382711053" >
                        <img loading="lazy"  src={Facebook} alt="" />
                    </a></li>
                <li className='social-list-item'><a href="https://www.instagram.com/mccanndesignsweb/" >
                        <img loading="lazy"  src={Instagram} alt="" />
                    </a></li>
                <li className='social-list-item'><a href="https://www.linkedin.com/in/mccanndesigns/" >
                        <img loading="lazy"  src={LinkedIn} alt="" />
                    </a></li>
                <li className='social-list-item'><a href="https://x.com/McCannDesignWeb" >
                        <img loading="lazy"  src={TwitterX} alt="" />
                    </a></li>
                <li className='social-list-item'><a href="https://www.youtube.com/@mccanndesignsweb" >
                        <img loading="lazy"  src={YouTube} alt="" />
                    </a></li>
            </ul>
        </div>
    </section>
  )
}

export default Footer