import LineWBall from "./LineWBall"

function ServiceCard({title, description}) {
  return (
    <div className="Card-Service">
        <div className="Card-Service-header-wrapper">
            <LineWBall />
            <h6 className="h6">{title}</h6>
            <LineWBall/>
        </div>
        <p className="body1">{description}</p>
    </div>
  )
}

export default ServiceCard