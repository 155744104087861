import React, { lazy, Suspense } from 'react';
import AboutSection from "./AboutSection";
import CTABanner from "./components/CTABanner";
import HeroSection from "./HeroSection";
import Services from "./Services";
import ContactSection from "./ContactSection";
import Loader from './components/Loader';
import {questionData} from './Data/QuestionData'
import {testimonialData} from './Data/testimonials'
import {ProjectData} from './Data/ProjectData'
const WhyUs = lazy(()=>import("./WhyUs"));
const Works = lazy(()=>import('./Works'));
const WhatTheySay = lazy(()=>import("./WhatTheySay"));
const FQASection = lazy(()=>import("./FQASection"));



function App() {
  return (
    <div className="App">
      <HeroSection/>
      <AboutSection/>
      <Suspense fallback={<Loader />}>
        <Works data={ProjectData}/>
      </Suspense>
      <CTABanner/>
      <Services/>
      <Suspense fallback={<Loader/>}>
        <WhyUs/>
      </Suspense>
      <CTABanner/>
      <Suspense fallback={<Loader/>}>
        <WhatTheySay data={testimonialData}/>
      </ Suspense>
      <Suspense fallback={<Loader />}>
        <FQASection data={questionData}/>
      </Suspense>
      <ContactSection />
    </div>
  );
}

export default App;
