export const testimonialData = [
    
    {
        "id":'001',
        "rating": 5,
        "post": "Working with McCann Design LLC was a game-changer for my business! Kevin transformed my outdated website into a modern, user-friendly platform that reflects my brand perfectly.",
        "customer": " Sarah L."
    },
    {
        "id":'002',
        "rating": 4,
        "post": "I recently hired McCann Design LLC to revamp my e-commerce site, and I'm thrilled with the results! Kevin's creativity and technical skills really brought my vision to life.",
        "customer": " Mark T."
    },
    {
        "id":'003',
        "rating": 5,
        "post": "Kevin from McCann Design LLC is a web wizard! He helped me optimize my site for SEO, and I've seen a significant increase in traffic since we launched the new design.",
        "customer": " Jessica R."
    },
    {
        "id":'004',
        "rating": 5,
        "post": "McCann Design LLC is simply the best! I was struggling with my website's layout and functionality, but Kevin stepped in and completely transformed it.",
        "customer": " Dave K."
    }
]