import React from 'react'

function LineWBall() {
  return (
    <div className='lineAsset'>
        <div className="lineAsset-ball"></div>
    </div>
  )
}

export default LineWBall