import React from 'react'
import Button from './Button'

function CTABanner() {
  return (
    <div className='CTA-banner'>
        <div className="ctaBanner-left-wing"></div>
        <div className="ctaBanner-content-wrapper">
            <div className="ctaBanner-left-wing-fold"></div>
            <div className="ctaBanner-content">
                <h3 className="h4">Step Into the Spotlight</h3>
                <Button openType={'_blank'} text='book a meeting today' type='button-cta float-shadow' hrefLink='https://calendly.com/kevin-mccanndesignsweb/30min'/>
            </div>
            <div className="ctaBanner-right-wing-fold"></div>
        </div>
        <div className="ctaBanner-right-wing"></div>
    </div>
  )
}

export default CTABanner