export const questionData = {
    "How do I get started with McCann Design?":"The first step is to reach out for a free consultation. We’ll discuss your needs, project goals, and budget to create a tailored plan. From there, we’ll outline the process, timeline, and cost structure so you know exactly what to expect.",
    
    "How much does a website cost?":"Pricing varies based on the complexity of the project. I offer both subscription and one-time pricing for single-page, multi-page, and e-commerce websites. Contact me for a customized quote that aligns with your budget and business needs.",
    
    "How long does it take to complete a website?":"The timeline depends on the project’s size and requirements. Typically, a basic website takes about 4-6 weeks, while larger or more complex sites may take longer. I’ll provide a timeline estimate during the initial consultation.",
    
    "Will my website be mobile-friendly?":"Absolutely. Every website I create is designed to be fully responsive, meaning it will look great and function smoothly on desktops, tablets, and smartphones.",
    
    "Can you help improve an existing website?":"Yes, I work with clients to analyze and improve existing websites. This includes optimizing design, performance, UX/UI, and ensuring SEO best practices are followed to boost your site’s effectiveness.",
    
    "What sets McCann Design apart from other web development agencies?":"I focus on crafting unique 'webstages' that spotlight your brand and engage your audience. With a background in design, coding, and user-centric experiences, I provide a blend of creativity and technical expertise tailored to meet your goals.",
    
    "Will I own my website once it’s completed?":"Absolutely. Once the website is finished and the project is complete, you have full ownership of the site, its code, and any assets I’ve created for you."
}
