import React from 'react';
import ServiceCard from './components/ServiceCard';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.registerPlugin(useGSAP);


function Services() {
    
    useGSAP(()=>{
        let cards = gsap.utils.toArray('.Card-Service');
        let cardIndex1 = cards.slice(0,3);
        let cardIndex2 = cards.slice(3,6);
        console.log(cards);
        
        gsap.to(cardIndex1,{
            scale: '1',
            scrollTrigger:{
                trigger:'.services-card-wrapper-01',
                start:'top center'
            },
            stagger:{
                each: 0.5
            }
        })
        gsap.to(cardIndex2,{
            scale: '1',
            scrollTrigger:{
                trigger:'.services-card-wrapper-02',
                start:'top center'
            },
            stagger:{
                each: 0.5
            }
        })

    })

  return (
    <section id='ServiceSection' className='ServiceSection'>
        <div className="services-background-assets">
            <div className="services-tri-dark"></div>
            <div className="services-tri-light"></div>
            <div className="services-tri-dark"></div>
        </div>
        <div className="services-card-wrapper services-card-wrapper-01">
            <ServiceCard title={`UI/UX Design`} description='Crafting user-friendly and visually appealing interfaces'/>
            <ServiceCard title='front & back-end devlopment' description='Building the infrastructure that powers your website'/>
            <ServiceCard title='E-commerce Development' description='Building online stores and integrating payment systems.'/>
        </div>
        <div className="section-banner">
            <div className="section-banner-leftwing"></div>
            <div className="section-banner-content">
                <div className="section-banner-leftwing-fold"></div>
                <h2 className="h3 services-header">The Complete Web Service Package</h2>
                <div className="section-banner-rightwing-fold"></div>
            </div>
            <div className="section-banner-rightwing"></div>
        </div>
        <div className="services-card-wrapper services-card-wrapper-02">
            <ServiceCard title='SEO Optimization' description='Enhancing site visibility on search engines, like google.'/>
            <ServiceCard title='Reviews & Audits' description='Detailed analysis of performance, UX/UI, and overall visual appeal.'/>
            <ServiceCard title='Consultation & Strategy' description='Offering expert advice on digital strategies and web technologies.'/>
        </div>
    </section>
  )
}

export default Services