import React, { useState } from 'react'
import logo from '../images/icons/MainLogo.png'

function Navigation() {

  const [isMenuMoblieOpen, setIsMenuMoblieOpen] = useState(false)

  const toggleMenu = (e)=>{
    e.preventDefault()

    var menu = document.getElementById('MainNav')

    if (isMenuMoblieOpen) {
      menu.style.transform= 'translateY(-100%)'
      setIsMenuMoblieOpen(isMenuMoblieOpen => !isMenuMoblieOpen)
      
    } else {
      menu.style.transform= 'translateY(0)'
      setIsMenuMoblieOpen(isMenuMoblieOpen => !isMenuMoblieOpen)
      
    }
    
  }

  const exitMenu = (e)=>{
    var menu = document.getElementById('MainNav')

    if (isMenuMoblieOpen) {
      setTimeout(() => {
        menu.style.transform= 'translateY(-100%)'
        setIsMenuMoblieOpen(isMenuMoblieOpen => !isMenuMoblieOpen)
        
      }, 500);
      
    }

  }


  return (
    <>
        <div data-isMenuOpen={isMenuMoblieOpen} onClick={toggleMenu} className="mobile-navigation-button-wrapper">
          <div className="hamburger-top"></div>
          <div className="hamburger-middle"></div>
          <div className="hamburger-bottom"></div>
        </div>
    <div id='MainNav' className='Main-Navigation full-bleed-wrapper'>
        <nav className="Navigation">
        <a onClick={exitMenu} href="/" className="home-button"><img loading="lazy"  src={logo} alt="" /></a>
        <ul className="Navigation-list">
            <li className="Navigation-item">
              <a onClick={exitMenu} href="/#AboutSection" className="button button-Navigation">about</a>
              <svg id="visual" viewBox="0 -100 900 700">
                  <path d="M306.1 -142C352.5 -96.5 315.2 32 250.3 141.8C185.3 251.7 92.7 342.8 19.5 331.6C-53.7 320.3 -107.4 206.7 -144.6 112.8C-181.9 19 -202.6 -55 -175.8 -89.3C-149 -123.5 -74.5 -118 27.7 -134C129.9 -150 259.8 -187.5 306.1 -142" fill="none"></path>
              </svg>
              </li>
            <li className="Navigation-item"><a onClick={exitMenu} href="/#WorkSection" className="button button-Navigation">work</a>
            <svg id="visual" viewBox="0 -100 900 700">
                  <path d="M306.1 -142C352.5 -96.5 315.2 32 250.3 141.8C185.3 251.7 92.7 342.8 19.5 331.6C-53.7 320.3 -107.4 206.7 -144.6 112.8C-181.9 19 -202.6 -55 -175.8 -89.3C-149 -123.5 -74.5 -118 27.7 -134C129.9 -150 259.8 -187.5 306.1 -142" fill="none"></path>
              </svg>
              </li>
            <li className="Navigation-item"><a onClick={exitMenu} href="/#FQASection" className="button button-Navigation">FQA</a>
            <svg id="visual" viewBox="0 -100 900 700">
                  <path d="M306.1 -142C352.5 -96.5 315.2 32 250.3 141.8C185.3 251.7 92.7 342.8 19.5 331.6C-53.7 320.3 -107.4 206.7 -144.6 112.8C-181.9 19 -202.6 -55 -175.8 -89.3C-149 -123.5 -74.5 -118 27.7 -134C129.9 -150 259.8 -187.5 306.1 -142" fill="none"></path>
              </svg>
              </li>
            <li className="Navigation-item"><a onClick={exitMenu} target='_blank' href="https://calendly.com/kevin-mccanndesignsweb/30min" className="button button-secondary">book a meeting</a></li>
        </ul>
        </nav>
        <div className="light-bar"></div>
    </div>
    </>
  )
}

export default Navigation