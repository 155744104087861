import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import './Scss/master.scss';
import Navigation from './components/Navigation';
import Footer from './Footer';
const ThankYouPage = lazy(()=> import('./pages/ThankYouPage'));
const LostPage = lazy(()=> import('./pages/LostPage'));
const ContactUsPage = lazy(()=> import('./pages/ContactUsPage'));
const CaseStudy = lazy(() => import('./pages/CaseStudy'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./pages/TermsConditions'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Navigation/>
    <BrowserRouter>
      <Routes>
        <Route  exact path="/" element={<App />}/>
        <Route path="/thankyou" element={
            <Suspense>
              <ThankYouPage />
            </Suspense>
          }/>
          <Route path="/caseStudy/:caseID" element={
            <Suspense>
              <CaseStudy/>
            </Suspense>
          }/>
        <Route path="/ContactUs" element={
            <Suspense>
              <ContactUsPage/>
            </Suspense>
          }/>
        <Route path='/PrivacyPolicy' element={
          <Suspense>
            <PrivacyPolicy/>
          </Suspense>
        }/>
        <Route path='/Terms' element={
          <Suspense>
            <TermsConditions/>
          </Suspense>
        }/>
        <Route path='/*' element={
            <Suspense>
              <LostPage/>
            </Suspense>
          }/>
      </Routes>
    </BrowserRouter>
    <Footer/>
  </React.StrictMode>
  
);
