export const ProjectData = [
    {
        'id': 1,
        'title': 'centerline cabinets',
        'hrefs':{
            'caseStudy':'centerline',
            'siteURL':'https://www.centerline-cabinets.net'
        },
        'images':{
            'main':require('../images/caseStudies/CenterLineCabinets/centerline_main.png'),
            'secondary':require('../images/caseStudies/CenterLineCabinets/centerline_secondary.png')
        }
        
    }
]


