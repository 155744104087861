import React from 'react'

function AboutSection() {
  return (
    <section id='AboutSection' className='aboutSection full-bleed-wrapper'>
        <p className="first-intro body1"><span>Hey there! I'm Kevin McCann, a web developer with a knack for transforming ideas into unique, functional, and visually striking websites. My journey began in the kitchen, where I worked as a chef for years before diving into the world of web design and development. Now, instead of plating dishes, I’m building digital experiences with creativity and precision.</span></p>
        <img loading="lazy"  className="image-1" src={require("./images/photos/PhotoOfMe02.jpg")} alt="" srcSet="" />
        <img loading="lazy"  className="image-2" src={require("./images/photos/PhotoOfMe01.jpg")} alt="" srcSet="" />
        <div className="section-header"><h2 className="h2">Code & Craftsmanship</h2></div>
        <img loading="lazy"  className="image-3" src="https://source.unsplash.com/" alt="" srcSet="" />
        <p className="last-intro body1"><span>I specialize in creating clean, responsive websites that not only look great but perform smoothly across all devices. Whether you’re a small business or an entrepreneur looking to make a mark online, I’m here to help put your brand in the spotlight with a website that works as hard as you do.</span></p>
    </section>
  )
}

export default AboutSection